import { GlobalNavigationSubItemsType } from "../../atoms/GlobalNavigationItem/GlobalNavigationItemType";

import styles from "./GlobalNavigationSubItem.module.scss"

const GlobalNavigationSubItems = (props: GlobalNavigationSubItemsType) => {
  const {item} = props;
  return (
    <>
      {item && (
        <ul className={styles.globalNavitaionSubItems}>
          {Object.keys(item).map((key) => {
            const itemElement = item[key];
            return (
              <li key={key}>
                <a href={`/${itemElement.url}/`}>{itemElement.text}</a>
              </li>
            );
          })}
        </ul>
      )}
      
    </>
  )
}
export default GlobalNavigationSubItems;
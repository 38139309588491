import GlobalNavigationItem from "../../atoms/GlobalNavigationItem/GlobalNavigationItem";
import { HeaderNavigationType } from "./GlobalNavigationItemsType";
import styles from "./GlobalNavigationItems.module.scss";
import GlobalNavigationSubItem from "../GlobalNavigationSubItem/GlobalNavigationSubItem";
import GlobalNavigationSubItems from "../GlobalNavigationSubItem/GlobalNavigationSubItem";

const GlobalNavigationItems = (props: HeaderNavigationType) => {
  const {id, globalNavigationItems} = props;

  return (
    <>
      {globalNavigationItems && (
        <div id={id}>
          {globalNavigationItems && (
            <ul className={styles.globalNavigationItems}>
              {Object.keys(globalNavigationItems).map((item, key) => {
                const navItem = globalNavigationItems[item];
                return (
                  <li key={navItem?.id || key}>
                    <div>
                      <GlobalNavigationItem 
                        id={navItem?.id}
                        text={navItem.text}
                        url={navItem.url}
                        hasSubMenu={navItem.hasSubMenu}
                        isExternal={navItem.isExternal}
                        commonClass={navItem.commonClass}
                        sub={navItem.sub}
                      />
                    </div>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )}
    </>
  )
}

export default GlobalNavigationItems;
import ImageItem from "../../atoms/ImageItem/ImageItem";
import VerticalNavigationitems from "../../molecules/VerticalNavigationitems/VerticalNavigationitems";
import HamburgerNavigationType from "./HamburgerNavigationType";
import styles from "./HamburgerNavigation.module.scss";

const HamburgerNavigation = (props: HamburgerNavigationType) => {
  const {id, hamburgerNavigationItems, imageItem} = props;

  return (
    <div id={id} className={styles.hamburgerNavigation}>
      <div id={id} className={styles.hamburgerNavigation__inner}>
        <div id={id} className={styles.hamburgerNavigation__items}>
          <VerticalNavigationitems
            id={hamburgerNavigationItems.id}
            navItems={hamburgerNavigationItems.navItems}
            columnNumbers={hamburgerNavigationItems.columnNumbers}
          />
        </div>
        <div id={id} className={styles.hamburgerNavigation__img}>
          <ImageItem
            id={imageItem.id}
            imgPCPath={imageItem.imgPCPath}
            imgSPPath={imageItem.imgSPPath}
            webpPCPath={imageItem.webpPCPath}
            webpSPPath={imageItem.webpSPPath}
            widthPC={imageItem.widthPC}
            heightPC={imageItem.heightPC}
            widthSP={imageItem.widthSP}
            heightSP={imageItem.heightSP}
            altText={imageItem.altText}
            commonClass={imageItem.commonClass}
          />
        </div>
      </div>
    </div>
  )
}

export default HamburgerNavigation;
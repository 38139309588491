import Copyright from "../../atoms/Copyright/Copyright";
import FooterInfo from "../../molecules/FooterInfo/FooterInfo";
import LogoBlock from "../../molecules/LogoBlock/LogoBlock";
import VerticalNavigationitems from "../../molecules/VerticalNavigationitems/VerticalNavigationitems";
import FooterBoxType from "./FooterBoxType";
import styles from "./FooterBox.module.scss";

const FooterBox = (props: FooterBoxType ) => {
  const {id, footerLogo, footerInfo, footerNavigation, copyright} = props;
  return (
    <div className={styles.footer__inner}>
      <div className={styles.footer__info}>
        <div className={styles.footer__info__logo}>
          <LogoBlock
            id={footerLogo.id}
            url={footerLogo.url}
            imageItem={footerLogo.imageItem}
          />
        </div>
        <div className={styles.footer__info__text}>
          <FooterInfo
            id={footerInfo.id}
            addressText={footerInfo.addressText}
            footerTelFax={footerInfo.footerTelFax}
          />
        </div>
      </div>
      <div className={styles.footer__navigation}>
        <VerticalNavigationitems 
          id={footerNavigation.id}
          navItems={footerNavigation.navItems}
          columnNumbers={footerNavigation.columnNumbers}
        />
      </div>
      <div className={styles.footer__copyright}>
        <Copyright
          id={copyright.id}
          text={copyright.text}
          commonClass={copyright.commonClass}
        />
      </div>
    </div>
  )
}

export default FooterBox;


import GlobalNavigationSubItems from "../../molecules/GlobalNavigationSubItem/GlobalNavigationSubItem";
import styles from "./GlobalNavigationItem.module.scss";
import GlobalNavigationItemType from "./GlobalNavigationItemType";

const GlobalNavigationItem = (props: GlobalNavigationItemType) => {
  const {id,text,url,isCurrent,hasSubMenu,isExternal,commonClass, sub} = props;
  return (
    <>
      <div className={`${styles.GlobalNavigationItem} GlobalNavigationItem`} id={id}>
        <a href={`/${url}/`}>{text}</a>
        {sub && (
          <div className={styles.globalNavigationItems__subMenu}>
            <GlobalNavigationSubItems
              item={sub}
            />
          </div>
        )}
      </div>
    </>
  )
}
export default GlobalNavigationItem;